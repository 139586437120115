import React from 'react';
import { Box, Heading, Text, UnorderedList, ListItem, VStack, Link, Flex } from '@chakra-ui/react';
import { BalloonIcon, BlueberryIcon } from './CustomIcons';
import { socialLinks } from './constants';

const CreatePageEmpty = () => {
	return (
		<Box maxWidth="800px" margin="auto" padding={8}>
			<VStack spacing={6} align="stretch">
				<Flex align="center" justify="center">
					<Heading>Image Generator</Heading>
					<BlueberryIcon boxSize={6} ml={2} color="blue.500" />
					<BalloonIcon boxSize={6} ml={0} color="red.500" />
				</Flex>

				<Text fontSize="xl" fontWeight="bold" color="red.300" textAlign="center">
					The image generator has been shut down - {" "}
					<Link
						href="https://www.deviantart.com/floatful/art/Spherify-1152546231"
						textDecoration="underline"
						_hover={{ color: 'blue.100' }}
						isExternal
					>
						Click here to learn more
					</Link>
				</Text>

				<Text fontSize="md" fontWeight="bold" textAlign="center">
					Thanks to everyone that enjoyed this fun project of mine! Although I'm disheartened shutting it down, this isn't the end. I have other plans for this website, so join the{" "}
					<Link
						href={socialLinks[0].url}
						textDecoration="underline"
						textDecorationColor="red.300"
						_hover={{ color: 'blue.100' }}
						isExternal
					>
						discord
					</Link>
					{" "}to keep updated or check back here in a few weeks.
				</Text>
			</VStack>
		</Box>
	);
};

export default CreatePageEmpty;